<template>
  <div class="px-2 py-5">
    <h1 class="title">Urticaria Activity Score (UAS7)</h1>
    <b-message type="is-info">
      El UAS7 es un sistema de puntuación simple para evaluar los signos y
      síntomas de la urticaria. Se basa en la puntuación de las ronchas y el
      picor por separado en una escala de 0 a 3 durante 7 días.
    </b-message>
    <section>
      <h1 class="title has-text-centered">Semana 1</h1>
      <br />
      <div>
        <div class="block">
          <strong>Día 1</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA1.RONCHAS.UAS7_SEMANA_1_DIA1_RONCHAS_R1
                "
                v-model="week1Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA1.RONCHAS.UAS7_SEMANA_1_DIA1_RONCHAS_R2
                "
                v-model="week1Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA1.RONCHAS.UAS7_SEMANA_1_DIA1_RONCHAS_R3
                "
                v-model="week1Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA1.RONCHAS.UAS7_SEMANA_1_DIA1_RONCHAS_R4
                "
                v-model="week1Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA1.PICAZON.UAS7_SEMANA_1_DIA1_PICAZON_R1
                "
                v-model="week1Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA1.PICAZON.UAS7_SEMANA_1_DIA1_PICAZON_R2
                "
                v-model="week1Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA1.PICAZON.UAS7_SEMANA_1_DIA1_PICAZON_R3
                "
                v-model="week1Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA1.UAS7_SEMANA_1_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA1.PICAZON.UAS7_SEMANA_1_DIA1_PICAZON_R4
                "
                v-model="week1Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 2</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA2.RONCHAS.UAS7_SEMANA_1_DIA2_RONCHAS_R1
                "
                v-model="week1Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA2.RONCHAS.UAS7_SEMANA_1_DIA2_RONCHAS_R2
                "
                v-model="week1Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA2.RONCHAS.UAS7_SEMANA_1_DIA2_RONCHAS_R3
                "
                v-model="week1Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA2.RONCHAS.UAS7_SEMANA_1_DIA2_RONCHAS_R4
                "
                v-model="week1Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA2.PICAZON.UAS7_SEMANA_1_DIA2_PICAZON_R1
                "
                v-model="week1Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA2.PICAZON.UAS7_SEMANA_1_DIA2_PICAZON_R2
                "
                v-model="week1Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA2.PICAZON.UAS7_SEMANA_1_DIA2_PICAZON_R3
                "
                v-model="week1Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA2.UAS7_SEMANA_1_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA2.PICAZON.UAS7_SEMANA_1_DIA2_PICAZON_R4
                "
                v-model="week1Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 3</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA3.RONCHAS.UAS7_SEMANA_1_DIA3_RONCHAS_R1
                "
                v-model="week1Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA3.RONCHAS.UAS7_SEMANA_1_DIA3_RONCHAS_R2
                "
                v-model="week1Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA3.RONCHAS.UAS7_SEMANA_1_DIA3_RONCHAS_R3
                "
                v-model="week1Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA3.RONCHAS.UAS7_SEMANA_1_DIA3_RONCHAS_R4
                "
                v-model="week1Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA3.PICAZON.UAS7_SEMANA_1_DIA3_PICAZON_R1
                "
                v-model="week1Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA3.PICAZON.UAS7_SEMANA_1_DIA3_PICAZON_R2
                "
                v-model="week1Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA3.PICAZON.UAS7_SEMANA_1_DIA3_PICAZON_R3
                "
                v-model="week1Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA3.UAS7_SEMANA_1_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA3.PICAZON.UAS7_SEMANA_1_DIA3_PICAZON_R4
                "
                v-model="week1Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <div class="block">
          <strong>Día 4</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA4.RONCHAS.UAS7_SEMANA_1_DIA4_RONCHAS_R1
                "
                v-model="week1Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA4.RONCHAS.UAS7_SEMANA_1_DIA4_RONCHAS_R2
                "
                v-model="week1Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA4.RONCHAS.UAS7_SEMANA_1_DIA4_RONCHAS_R3
                "
                v-model="week1Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA4.RONCHAS.UAS7_SEMANA_1_DIA4_RONCHAS_R4
                "
                v-model="week1Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA4.PICAZON.UAS7_SEMANA_1_DIA4_PICAZON_R1
                "
                v-model="week1Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA4.PICAZON.UAS7_SEMANA_1_DIA4_PICAZON_R2
                "
                v-model="week1Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA4.PICAZON.UAS7_SEMANA_1_DIA4_PICAZON_R3
                "
                v-model="week1Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA4.UAS7_SEMANA_1_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA4.PICAZON.UAS7_SEMANA_1_DIA4_PICAZON_R4
                "
                v-model="week1Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 5</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA5.RONCHAS.UAS7_SEMANA_1_DIA5_RONCHAS_R1
                "
                v-model="week1Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA5.RONCHAS.UAS7_SEMANA_1_DIA5_RONCHAS_R2
                "
                v-model="week1Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA5.RONCHAS.UAS7_SEMANA_1_DIA5_RONCHAS_R3
                "
                v-model="week1Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA5.RONCHAS.UAS7_SEMANA_1_DIA5_RONCHAS_R4
                "
                v-model="week1Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA5.PICAZON.UAS7_SEMANA_1_DIA5_PICAZON_R1
                "
                v-model="week1Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA5.PICAZON.UAS7_SEMANA_1_DIA5_PICAZON_R2
                "
                v-model="week1Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA5.PICAZON.UAS7_SEMANA_1_DIA5_PICAZON_R3
                "
                v-model="week1Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA5.UAS7_SEMANA_1_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA5.PICAZON.UAS7_SEMANA_1_DIA5_PICAZON_R4
                "
                v-model="week1Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div>
        <div class="block">
          <strong>Día 6</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA6.RONCHAS.UAS7_SEMANA_1_DIA6_RONCHAS_R1
                "
                v-model="week1Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA6.RONCHAS.UAS7_SEMANA_1_DIA6_RONCHAS_R2
                "
                v-model="week1Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA6.RONCHAS.UAS7_SEMANA_1_DIA6_RONCHAS_R3
                "
                v-model="week1Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA6.RONCHAS.UAS7_SEMANA_1_DIA6_RONCHAS_R4
                "
                v-model="week1Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA6.PICAZON.UAS7_SEMANA_1_DIA6_PICAZON_R1
                "
                v-model="week1Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA6.PICAZON.UAS7_SEMANA_1_DIA6_PICAZON_R2
                "
                v-model="week1Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA6.PICAZON.UAS7_SEMANA_1_DIA6_PICAZON_R3
                "
                v-model="week1Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA6.UAS7_SEMANA_1_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA6.PICAZON.UAS7_SEMANA_1_DIA6_PICAZON_R4
                "
                v-model="week1Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 7</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA7.RONCHAS.UAS7_SEMANA_1_DIA7_RONCHAS_R1
                "
                v-model="week1Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA7.RONCHAS.UAS7_SEMANA_1_DIA7_RONCHAS_R2
                "
                v-model="week1Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA7.RONCHAS.UAS7_SEMANA_1_DIA7_RONCHAS_R3
                "
                v-model="week1Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA1.DIA7.RONCHAS.UAS7_SEMANA_1_DIA7_RONCHAS_R4
                "
                v-model="week1Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA7.PICAZON.UAS7_SEMANA_1_DIA7_PICAZON_R1
                "
                v-model="week1Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA7.PICAZON.UAS7_SEMANA_1_DIA7_PICAZON_R2
                "
                v-model="week1Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA7.PICAZON.UAS7_SEMANA_1_DIA7_PICAZON_R3
                "
                v-model="week1Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA1.DIA7.UAS7_SEMANA_1_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA1.DIA7.PICAZON.UAS7_SEMANA_1_DIA7_PICAZON_R4
                "
                v-model="week1Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="column has-text-right">
          <strong class="week-result"
            >Resultado Semana 1: {{ week1 }}
            <strong v-if="week1Text != ''">({{ week1Text }})</strong></strong
          >
        </div>
      </div>
      <hr />
      <h1 class="title has-text-centered">Semana 2</h1>
      <br />
      <div>
        <div class="block">
          <strong>Día 1</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA1.RONCHAS.UAS7_SEMANA_2_DIA1_RONCHAS_R1
                "
                v-model="week2Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA1.RONCHAS.UAS7_SEMANA_2_DIA1_RONCHAS_R2
                "
                v-model="week2Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA1.RONCHAS.UAS7_SEMANA_2_DIA1_RONCHAS_R3
                "
                v-model="week2Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA1.RONCHAS.UAS7_SEMANA_2_DIA1_RONCHAS_R4
                "
                v-model="week2Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA1.PICAZON.UAS7_SEMANA_2_DIA1_PICAZON_R1
                "
                v-model="week2Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA1.PICAZON.UAS7_SEMANA_2_DIA1_PICAZON_R2
                "
                v-model="week2Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA1.PICAZON.UAS7_SEMANA_2_DIA1_PICAZON_R3
                "
                v-model="week2Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA1.UAS7_SEMANA_2_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA1.PICAZON.UAS7_SEMANA_2_DIA1_PICAZON_R4
                "
                v-model="week2Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <div class="block">
          <strong>Día 2</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA2.RONCHAS.UAS7_SEMANA_2_DIA2_RONCHAS_R1
                "
                v-model="week2Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA2.RONCHAS.UAS7_SEMANA_2_DIA2_RONCHAS_R2
                "
                v-model="week2Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA2.RONCHAS.UAS7_SEMANA_2_DIA2_RONCHAS_R3
                "
                v-model="week2Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA2.RONCHAS.UAS7_SEMANA_2_DIA2_RONCHAS_R4
                "
                v-model="week2Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA2.PICAZON.UAS7_SEMANA_2_DIA2_PICAZON_R1
                "
                v-model="week2Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA2.PICAZON.UAS7_SEMANA_2_DIA2_PICAZON_R2
                "
                v-model="week2Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA2.PICAZON.UAS7_SEMANA_2_DIA2_PICAZON_R3
                "
                v-model="week2Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA2.UAS7_SEMANA_2_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA2.PICAZON.UAS7_SEMANA_2_DIA2_PICAZON_R4
                "
                v-model="week2Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 3</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA3.RONCHAS.UAS7_SEMANA_2_DIA3_RONCHAS_R1
                "
                v-model="week2Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA3.RONCHAS.UAS7_SEMANA_2_DIA3_RONCHAS_R2
                "
                v-model="week2Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA3.RONCHAS.UAS7_SEMANA_2_DIA3_RONCHAS_R3
                "
                v-model="week2Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA3.RONCHAS.UAS7_SEMANA_2_DIA3_RONCHAS_R4
                "
                v-model="week2Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA3.PICAZON.UAS7_SEMANA_2_DIA3_PICAZON_R1
                "
                v-model="week2Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA3.PICAZON.UAS7_SEMANA_2_DIA3_PICAZON_R2
                "
                v-model="week2Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA3.PICAZON.UAS7_SEMANA_2_DIA3_PICAZON_R3
                "
                v-model="week2Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA3.UAS7_SEMANA_2_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA3.PICAZON.UAS7_SEMANA_2_DIA3_PICAZON_R4
                "
                v-model="week2Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 4</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA4.RONCHAS.UAS7_SEMANA_2_DIA4_RONCHAS_R1
                "
                v-model="week2Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA4.RONCHAS.UAS7_SEMANA_2_DIA4_RONCHAS_R2
                "
                v-model="week2Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA4.RONCHAS.UAS7_SEMANA_2_DIA4_RONCHAS_R3
                "
                v-model="week2Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA4.RONCHAS.UAS7_SEMANA_2_DIA4_RONCHAS_R4
                "
                v-model="week2Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA4.PICAZON.UAS7_SEMANA_2_DIA4_PICAZON_R1
                "
                v-model="week2Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA4.PICAZON.UAS7_SEMANA_2_DIA4_PICAZON_R2
                "
                v-model="week2Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA4.PICAZON.UAS7_SEMANA_2_DIA4_PICAZON_R3
                "
                v-model="week2Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA4.UAS7_SEMANA_2_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA4.PICAZON.UAS7_SEMANA_2_DIA4_PICAZON_R4
                "
                v-model="week2Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 5</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA5.RONCHAS.UAS7_SEMANA_2_DIA5_RONCHAS_R1
                "
                v-model="week2Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA5.RONCHAS.UAS7_SEMANA_2_DIA5_RONCHAS_R2
                "
                v-model="week2Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA5.RONCHAS.UAS7_SEMANA_2_DIA5_RONCHAS_R3
                "
                v-model="week2Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA5.RONCHAS.UAS7_SEMANA_2_DIA5_RONCHAS_R4
                "
                v-model="week2Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA5.PICAZON.UAS7_SEMANA_2_DIA5_PICAZON_R1
                "
                v-model="week2Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA5.PICAZON.UAS7_SEMANA_2_DIA5_PICAZON_R2
                "
                v-model="week2Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA5.PICAZON.UAS7_SEMANA_2_DIA5_PICAZON_R3
                "
                v-model="week2Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA5.UAS7_SEMANA_2_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA5.PICAZON.UAS7_SEMANA_2_DIA5_PICAZON_R4
                "
                v-model="week2Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 6</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA6.RONCHAS.UAS7_SEMANA_2_DIA6_RONCHAS_R1
                "
                v-model="week2Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA6.RONCHAS.UAS7_SEMANA_2_DIA6_RONCHAS_R2
                "
                v-model="week2Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA6.RONCHAS.UAS7_SEMANA_2_DIA6_RONCHAS_R3
                "
                v-model="week2Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA6.RONCHAS.UAS7_SEMANA_2_DIA6_RONCHAS_R4
                "
                v-model="week2Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA6.PICAZON.UAS7_SEMANA_2_DIA6_PICAZON_R1
                "
                v-model="week2Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA6.PICAZON.UAS7_SEMANA_2_DIA6_PICAZON_R2
                "
                v-model="week2Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA6.PICAZON.UAS7_SEMANA_2_DIA6_PICAZON_R3
                "
                v-model="week2Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA6.UAS7_SEMANA_2_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA6.PICAZON.UAS7_SEMANA_2_DIA6_PICAZON_R4
                "
                v-model="week2Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 7</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA7.RONCHAS.UAS7_SEMANA_2_DIA7_RONCHAS_R1
                "
                v-model="week2Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA7.RONCHAS.UAS7_SEMANA_2_DIA7_RONCHAS_R2
                "
                v-model="week2Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA7.RONCHAS.UAS7_SEMANA_2_DIA7_RONCHAS_R3
                "
                v-model="week2Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA2.DIA7.RONCHAS.UAS7_SEMANA_2_DIA7_RONCHAS_R4
                "
                v-model="week2Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA7.PICAZON.UAS7_SEMANA_2_DIA7_PICAZON_R1
                "
                v-model="week2Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA7.PICAZON.UAS7_SEMANA_2_DIA7_PICAZON_R2
                "
                v-model="week2Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA7.PICAZON.UAS7_SEMANA_2_DIA7_PICAZON_R3
                "
                v-model="week2Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA2.DIA7.UAS7_SEMANA_2_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA2.DIA7.PICAZON.UAS7_SEMANA_2_DIA7_PICAZON_R4
                "
                v-model="week2Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="column has-text-right">
          <strong class="week-result"
            >Resultado Semana 2: {{ week2 }}
            <strong v-if="week2Text != ''">({{ week2Text }})</strong></strong
          >
        </div>
      </div>
      <hr />
      <h1 class="title has-text-centered">Semana 3</h1>
      <br />
      <div>
        <div class="block">
          <strong>Día 1</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA1.RONCHAS.UAS7_SEMANA_3_DIA1_RONCHAS_R1
                "
                v-model="week3Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA1.RONCHAS.UAS7_SEMANA_3_DIA1_RONCHAS_R2
                "
                v-model="week3Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA1.RONCHAS.UAS7_SEMANA_3_DIA1_RONCHAS_R3
                "
                v-model="week3Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA1.RONCHAS.UAS7_SEMANA_3_DIA1_RONCHAS_R4
                "
                v-model="week3Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA1.PICAZON.UAS7_SEMANA_3_DIA1_PICAZON_R1
                "
                v-model="week3Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA1.PICAZON.UAS7_SEMANA_3_DIA1_PICAZON_R2
                "
                v-model="week3Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA1.PICAZON.UAS7_SEMANA_3_DIA1_PICAZON_R3
                "
                v-model="week3Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA1.UAS7_SEMANA_3_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA1.PICAZON.UAS7_SEMANA_3_DIA1_PICAZON_R4
                "
                v-model="week3Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 2</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA2.RONCHAS.UAS7_SEMANA_3_DIA2_RONCHAS_R1
                "
                v-model="week3Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA2.RONCHAS.UAS7_SEMANA_3_DIA2_RONCHAS_R2
                "
                v-model="week3Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA2.RONCHAS.UAS7_SEMANA_3_DIA2_RONCHAS_R3
                "
                v-model="week3Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA2.RONCHAS.UAS7_SEMANA_3_DIA2_RONCHAS_R4
                "
                v-model="week3Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA2.PICAZON.UAS7_SEMANA_3_DIA2_PICAZON_R1
                "
                v-model="week3Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA2.PICAZON.UAS7_SEMANA_3_DIA2_PICAZON_R2
                "
                v-model="week3Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA2.PICAZON.UAS7_SEMANA_3_DIA2_PICAZON_R3
                "
                v-model="week3Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA2.UAS7_SEMANA_3_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA2.PICAZON.UAS7_SEMANA_3_DIA2_PICAZON_R4
                "
                v-model="week3Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 3</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA3.RONCHAS.UAS7_SEMANA_3_DIA3_RONCHAS_R1
                "
                v-model="week3Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA3.RONCHAS.UAS7_SEMANA_3_DIA3_RONCHAS_R2
                "
                v-model="week3Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA3.RONCHAS.UAS7_SEMANA_3_DIA3_RONCHAS_R3
                "
                v-model="week3Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA3.RONCHAS.UAS7_SEMANA_3_DIA3_RONCHAS_R4
                "
                v-model="week3Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA3.PICAZON.UAS7_SEMANA_3_DIA3_PICAZON_R1
                "
                v-model="week3Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA3.PICAZON.UAS7_SEMANA_3_DIA3_PICAZON_R2
                "
                v-model="week3Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA3.PICAZON.UAS7_SEMANA_3_DIA3_PICAZON_R3
                "
                v-model="week3Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA3.UAS7_SEMANA_3_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA3.PICAZON.UAS7_SEMANA_3_DIA3_PICAZON_R4
                "
                v-model="week3Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 4</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA4.RONCHAS.UAS7_SEMANA_3_DIA4_RONCHAS_R1
                "
                v-model="week3Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA4.RONCHAS.UAS7_SEMANA_3_DIA4_RONCHAS_R2
                "
                v-model="week3Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA4.RONCHAS.UAS7_SEMANA_3_DIA4_RONCHAS_R3
                "
                v-model="week3Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA4.RONCHAS.UAS7_SEMANA_3_DIA4_RONCHAS_R4
                "
                v-model="week3Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA4.PICAZON.UAS7_SEMANA_3_DIA4_PICAZON_R1
                "
                v-model="week3Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA4.PICAZON.UAS7_SEMANA_3_DIA4_PICAZON_R2
                "
                v-model="week3Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA4.PICAZON.UAS7_SEMANA_3_DIA4_PICAZON_R3
                "
                v-model="week3Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA4.UAS7_SEMANA_3_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA4.PICAZON.UAS7_SEMANA_3_DIA4_PICAZON_R4
                "
                v-model="week3Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 5</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA5.RONCHAS.UAS7_SEMANA_3_DIA5_RONCHAS_R1
                "
                v-model="week3Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA5.RONCHAS.UAS7_SEMANA_3_DIA5_RONCHAS_R2
                "
                v-model="week3Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA5.RONCHAS.UAS7_SEMANA_3_DIA5_RONCHAS_R3
                "
                v-model="week3Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA5.RONCHAS.UAS7_SEMANA_3_DIA5_RONCHAS_R4
                "
                v-model="week3Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA5.PICAZON.UAS7_SEMANA_3_DIA5_PICAZON_R1
                "
                v-model="week3Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA5.PICAZON.UAS7_SEMANA_3_DIA5_PICAZON_R2
                "
                v-model="week3Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA5.PICAZON.UAS7_SEMANA_3_DIA5_PICAZON_R3
                "
                v-model="week3Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA5.UAS7_SEMANA_3_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA5.PICAZON.UAS7_SEMANA_3_DIA5_PICAZON_R4
                "
                v-model="week3Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 6</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA6.RONCHAS.UAS7_SEMANA_3_DIA6_RONCHAS_R1
                "
                v-model="week3Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA6.RONCHAS.UAS7_SEMANA_3_DIA6_RONCHAS_R2
                "
                v-model="week3Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA6.RONCHAS.UAS7_SEMANA_3_DIA6_RONCHAS_R3
                "
                v-model="week3Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA6.RONCHAS.UAS7_SEMANA_3_DIA6_RONCHAS_R4
                "
                v-model="week3Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA6.PICAZON.UAS7_SEMANA_3_DIA6_PICAZON_R1
                "
                v-model="week3Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA6.PICAZON.UAS7_SEMANA_3_DIA6_PICAZON_R2
                "
                v-model="week3Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA6.PICAZON.UAS7_SEMANA_3_DIA6_PICAZON_R3
                "
                v-model="week3Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA6.UAS7_SEMANA_3_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA6.PICAZON.UAS7_SEMANA_3_DIA6_PICAZON_R4
                "
                v-model="week3Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 7</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA7.RONCHAS.UAS7_SEMANA_3_DIA7_RONCHAS_R1
                "
                v-model="week3Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA7.RONCHAS.UAS7_SEMANA_3_DIA7_RONCHAS_R2
                "
                v-model="week3Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA7.RONCHAS.UAS7_SEMANA_3_DIA7_RONCHAS_R3
                "
                v-model="week3Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA3.DIA7.RONCHAS.UAS7_SEMANA_3_DIA7_RONCHAS_R4
                "
                v-model="week3Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA7.PICAZON.UAS7_SEMANA_3_DIA7_PICAZON_R1
                "
                v-model="week3Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA7.PICAZON.UAS7_SEMANA_3_DIA7_PICAZON_R2
                "
                v-model="week3Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA7.PICAZON.UAS7_SEMANA_3_DIA7_PICAZON_R3
                "
                v-model="week3Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA3.DIA7.UAS7_SEMANA_3_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA3.DIA7.PICAZON.UAS7_SEMANA_3_DIA7_PICAZON_R4
                "
                v-model="week3Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="column has-text-right">
          <strong class="week-result"
            >Resultado Semana 3: {{ week3 }}
            <strong v-if="week3Text != ''">({{ week3Text }})</strong></strong
          >
        </div>
      </div>
      <hr />
      <h1 class="title has-text-centered">Semana 4</h1>
      <br />
      <div>
        <div class="block">
          <strong>Día 1</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA1.RONCHAS.UAS7_SEMANA_4_DIA1_RONCHAS_R1
                "
                v-model="week4Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA1.RONCHAS.UAS7_SEMANA_4_DIA1_RONCHAS_R2
                "
                v-model="week4Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA1.RONCHAS.UAS7_SEMANA_4_DIA1_RONCHAS_R3
                "
                v-model="week4Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA1.RONCHAS.UAS7_SEMANA_4_DIA1_RONCHAS_R4
                "
                v-model="week4Day1Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA1.PICAZON.UAS7_SEMANA_4_DIA1_PICAZON_R1
                "
                v-model="week4Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA1.PICAZON.UAS7_SEMANA_4_DIA1_PICAZON_R2
                "
                v-model="week4Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA1.PICAZON.UAS7_SEMANA_4_DIA1_PICAZON_R3
                "
                v-model="week4Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA1.UAS7_SEMANA_4_DIA1_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA1.PICAZON.UAS7_SEMANA_4_DIA1_PICAZON_R4
                "
                v-model="week4Day1Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 2</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA2.RONCHAS.UAS7_SEMANA_4_DIA2_RONCHAS_R1
                "
                v-model="week4Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA2.RONCHAS.UAS7_SEMANA_4_DIA2_RONCHAS_R2
                "
                v-model="week4Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA2.RONCHAS.UAS7_SEMANA_4_DIA2_RONCHAS_R3
                "
                v-model="week4Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA2.RONCHAS.UAS7_SEMANA_4_DIA2_RONCHAS_R4
                "
                v-model="week4Day2Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA2.PICAZON.UAS7_SEMANA_4_DIA2_PICAZON_R1
                "
                v-model="week4Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA2.PICAZON.UAS7_SEMANA_4_DIA2_PICAZON_R2
                "
                v-model="week4Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA2.PICAZON.UAS7_SEMANA_4_DIA2_PICAZON_R3
                "
                v-model="week4Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA2.UAS7_SEMANA_4_DIA2_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA2.PICAZON.UAS7_SEMANA_4_DIA2_PICAZON_R4
                "
                v-model="week4Day2Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 3</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA3.RONCHAS.UAS7_SEMANA_4_DIA3_RONCHAS_R1
                "
                v-model="week4Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA3.RONCHAS.UAS7_SEMANA_4_DIA3_RONCHAS_R2
                "
                v-model="week4Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA3.RONCHAS.UAS7_SEMANA_4_DIA3_RONCHAS_R3
                "
                v-model="week4Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA3.RONCHAS.UAS7_SEMANA_4_DIA3_RONCHAS_R4
                "
                v-model="week4Day3Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA3.PICAZON.UAS7_SEMANA_4_DIA3_PICAZON_R1
                "
                v-model="week4Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA3.PICAZON.UAS7_SEMANA_4_DIA3_PICAZON_R2
                "
                v-model="week4Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA3.PICAZON.UAS7_SEMANA_4_DIA3_PICAZON_R3
                "
                v-model="week4Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA3.UAS7_SEMANA_4_DIA3_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA3.PICAZON.UAS7_SEMANA_4_DIA3_PICAZON_R4
                "
                v-model="week4Day3Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 4</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA4.RONCHAS.UAS7_SEMANA_4_DIA4_RONCHAS_R1
                "
                v-model="week4Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA4.RONCHAS.UAS7_SEMANA_4_DIA4_RONCHAS_R2
                "
                v-model="week4Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA4.RONCHAS.UAS7_SEMANA_4_DIA4_RONCHAS_R3
                "
                v-model="week4Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA4.RONCHAS.UAS7_SEMANA_4_DIA4_RONCHAS_R4
                "
                v-model="week4Day4Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA4.PICAZON.UAS7_SEMANA_4_DIA4_PICAZON_R1
                "
                v-model="week4Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA4.PICAZON.UAS7_SEMANA_4_DIA4_PICAZON_R2
                "
                v-model="week4Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA4.PICAZON.UAS7_SEMANA_4_DIA4_PICAZON_R3
                "
                v-model="week4Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA4.UAS7_SEMANA_4_DIA4_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA4.PICAZON.UAS7_SEMANA_4_DIA4_PICAZON_R4
                "
                v-model="week4Day4Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 5</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA5.RONCHAS.UAS7_SEMANA_4_DIA5_RONCHAS_R1
                "
                v-model="week4Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA5.RONCHAS.UAS7_SEMANA_4_DIA5_RONCHAS_R2
                "
                v-model="week4Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA5.RONCHAS.UAS7_SEMANA_4_DIA5_RONCHAS_R3
                "
                v-model="week4Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA5.RONCHAS.UAS7_SEMANA_4_DIA5_RONCHAS_R4
                "
                v-model="week4Day5Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA5.PICAZON.UAS7_SEMANA_4_DIA5_PICAZON_R1
                "
                v-model="week4Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA5.PICAZON.UAS7_SEMANA_4_DIA5_PICAZON_R2
                "
                v-model="week4Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA5.PICAZON.UAS7_SEMANA_4_DIA5_PICAZON_R3
                "
                v-model="week4Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA5.UAS7_SEMANA_4_DIA5_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA5.PICAZON.UAS7_SEMANA_4_DIA5_PICAZON_R4
                "
                v-model="week4Day5Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 6</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA6.RONCHAS.UAS7_SEMANA_4_DIA6_RONCHAS_R1
                "
                v-model="week4Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA6.RONCHAS.UAS7_SEMANA_4_DIA6_RONCHAS_R2
                "
                v-model="week4Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA6.RONCHAS.UAS7_SEMANA_4_DIA6_RONCHAS_R3
                "
                v-model="week4Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA6.RONCHAS.UAS7_SEMANA_4_DIA6_RONCHAS_R4
                "
                v-model="week4Day6Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA6.PICAZON.UAS7_SEMANA_4_DIA6_PICAZON_R1
                "
                v-model="week4Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA6.PICAZON.UAS7_SEMANA_4_DIA6_PICAZON_R2
                "
                v-model="week4Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA6.PICAZON.UAS7_SEMANA_4_DIA6_PICAZON_R3
                "
                v-model="week4Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA6.UAS7_SEMANA_4_DIA6_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA6.PICAZON.UAS7_SEMANA_4_DIA6_PICAZON_R4
                "
                v-model="week4Day6Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="block">
          <strong>Día 7</strong>
        </div>
        <div class="block">
          <span>
            Número de ronchas
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA7.RONCHAS.UAS7_SEMANA_4_DIA7_RONCHAS_R1
                "
                v-model="week4Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA7.RONCHAS.UAS7_SEMANA_4_DIA7_RONCHAS_R2
                "
                v-model="week4Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA7.RONCHAS.UAS7_SEMANA_4_DIA7_RONCHAS_R3
                "
                v-model="week4Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_RONCHAS"
                :native-value="
                  answers.SEMANA4.DIA7.RONCHAS.UAS7_SEMANA_4_DIA7_RONCHAS_R4
                "
                v-model="week4Day7Wheal"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block">
          <span>
            Intensidad de la picazón
          </span>

          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA7.PICAZON.UAS7_SEMANA_4_DIA7_PICAZON_R1
                "
                v-model="week4Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                0
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA7.PICAZON.UAS7_SEMANA_4_DIA7_PICAZON_R2
                "
                v-model="week4Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                1
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA7.PICAZON.UAS7_SEMANA_4_DIA7_PICAZON_R3
                "
                v-model="week4Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                2
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                :disabled="queryMode"
                :name="questions.SEMANA4.DIA7.UAS7_SEMANA_4_DIA7_PICAZON"
                :native-value="
                  answers.SEMANA4.DIA7.PICAZON.UAS7_SEMANA_4_DIA7_PICAZON_R4
                "
                v-model="week4Day7Itchign"
                @input="saveTermporalSulrvey"
              >
                3
              </b-radio>
            </div>
          </div>
        </div>
        <div class="column has-text-right">
          <strong class="week-result"
            >Resultado Semana 4: {{ week4 }}
            <strong v-if="week4Text != ''">({{ week4Text }})</strong></strong
          >
        </div>
      </div>

      <div class="block has-text-centered title mt-5" v-if="!queryMode">
        El resultado es: {{ score }}
        <span v-if="isIncompleted">(Incompleto)</span>
      </div>

      <div class="block has-text-centered title mt-5" v-if="queryMode">
        El resultado es: {{ result }}
      </div>

      <div class="buttons is-centered" v-if="!queryMode">
        <b-button class="clear-button" icon-right="block-helper" @click="clear"
          >Limpiar</b-button
        >
        <b-button class="save-button" icon-right="check-bold" @click="save"
          >Guardar</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { UAS7 as questions } from "../../../data/questions";
import { UAS7 as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      activeStep: 0,
      showSocial: true,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      restoreData: {},
      week1Day1Itchign: "",
      week1Day1Wheal: "",
      week1Day2Itchign: "",
      week1Day2Wheal: "",
      week1Day3Itchign: "",
      week1Day3Wheal: "",
      week1Day4Itchign: "",
      week1Day4Wheal: "",
      week1Day5Itchign: "",
      week1Day5Wheal: "",
      week1Day6Itchign: "",
      week1Day6Wheal: "",
      week1Day7Itchign: "",
      week1Day7Wheal: "",
      week2Day1Itchign: "",
      week2Day1Wheal: "",
      week2Day2Itchign: "",
      week2Day2Wheal: "",
      week2Day3Itchign: "",
      week2Day3Wheal: "",
      week2Day4Itchign: "",
      week2Day4Wheal: "",
      week2Day5Itchign: "",
      week2Day5Wheal: "",
      week2Day6Itchign: "",
      week2Day6Wheal: "",
      week2Day7Itchign: "",
      week2Day7Wheal: "",
      week3Day1Itchign: "",
      week3Day1Wheal: "",
      week3Day2Itchign: "",
      week3Day2Wheal: "",
      week3Day3Itchign: "",
      week3Day3Wheal: "",
      week3Day4Itchign: "",
      week3Day4Wheal: "",
      week3Day5Itchign: "",
      week3Day5Wheal: "",
      week3Day6Itchign: "",
      week3Day6Wheal: "",
      week3Day7Itchign: "",
      week3Day7Wheal: "",
      week4Day1Itchign: "",
      week4Day1Wheal: "",
      week4Day2Itchign: "",
      week4Day2Wheal: "",
      week4Day3Itchign: "",
      week4Day3Wheal: "",
      week4Day4Itchign: "",
      week4Day4Wheal: "",
      week4Day5Itchign: "",
      week4Day5Wheal: "",
      week4Day6Itchign: "",
      week4Day6Wheal: "",
      week4Day7Itchign: "",
      week4Day7Wheal: "",
      queryMode: false,
      result: "",
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "Uas7List" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.restoreData = this.$route.params.temporalData;

      this.week1Day1Itchign = this.$route.params.temporalData.week1Day1Itchign;
      this.week1Day1Wheal = this.$route.params.temporalData.week1Day1Wheal;
      this.week1Day2Itchign = this.answers.SEMANA1.DIA1.RONCHAS.UAS7_SEMANA_1_DIA1_RONCHAS_R1;
      this.week1Day2Wheal = this.$route.params.temporalData.week1Day2Wheal;
      this.week1Day3Itchign = this.$route.params.temporalData.week1Day3Itchign;
      this.week1Day3Wheal = this.$route.params.temporalData.week1Day3Wheal;
      this.week1Day4Itchign = this.$route.params.temporalData.week1Day4Itchign;
      this.week1Day4Wheal = this.$route.params.temporalData.week1Day4Wheal;
      this.week1Day5Itchign = this.$route.params.temporalData.week1Day5Itchign;
      this.week1Day5Wheal = this.$route.params.temporalData.week1Day5Wheal;
      this.week1Day6Itchign = this.$route.params.temporalData.week1Day6Itchign;
      this.week1Day6Wheal = this.$route.params.temporalData.week1Day6Wheal;
      this.week1Day7Itchign = this.$route.params.temporalData.week1Day7Itchign;
      this.week1Day7Wheal = this.$route.params.temporalData.week1Day7Wheal;
      this.week2Day1Itchign = this.$route.params.temporalData.week2Day1Itchign;
      this.week2Day1Wheal = this.$route.params.temporalData.week2Day1Wheal;
      this.week2Day2Itchign = this.$route.params.temporalData.week2Day2Itchign;
      this.week2Day2Wheal = this.$route.params.temporalData.week2Day2Wheal;
      this.week2Day3Itchign = this.$route.params.temporalData.week2Day3Itchign;
      this.week2Day3Wheal = this.$route.params.temporalData.week2Day3Wheal;
      this.week2Day4Itchign = this.$route.params.temporalData.week2Day4Itchign;
      this.week2Day4Wheal = this.$route.params.temporalData.week2Day4Wheal;
      this.week2Day5Itchign = this.$route.params.temporalData.week2Day5Itchign;
      this.week2Day5Wheal = this.$route.params.temporalData.week2Day5Wheal;
      this.week2Day6Itchign = this.$route.params.temporalData.week2Day6Itchign;
      this.week2Day6Wheal = this.$route.params.temporalData.week2Day6Wheal;
      this.week2Day7Itchign = this.$route.params.temporalData.week2Day7Itchign;
      this.week2Day7Wheal = this.$route.params.temporalData.week2Day7Wheal;
      this.week3Day1Itchign = this.$route.params.temporalData.week3Day1Itchign;
      this.week3Day1Wheal = this.$route.params.temporalData.week3Day1Wheal;
      this.week3Day2Itchign = this.$route.params.temporalData.week3Day2Itchign;
      this.week3Day2Wheal = this.$route.params.temporalData.week3Day2Wheal;
      this.week3Day3Itchign = this.$route.params.temporalData.week3Day3Itchign;
      this.week3Day3Wheal = this.$route.params.temporalData.week3Day3Wheal;
      this.week3Day4Itchign = this.$route.params.temporalData.week3Day4Itchign;
      this.week3Day4Wheal = this.$route.params.temporalData.week3Day4Wheal;
      this.week3Day5Itchign = this.$route.params.temporalData.week3Day5Itchign;
      this.week3Day5Wheal = this.$route.params.temporalData.week3Day5Wheal;
      this.week3Day6Itchign = this.$route.params.temporalData.week3Day6Itchign;
      this.week3Day6Wheal = this.$route.params.temporalData.week3Day6Wheal;
      this.week3Day7Itchign = this.$route.params.temporalData.week3Day7Itchign;
      this.week3Day7Wheal = this.$route.params.temporalData.week3Day7Wheal;
      this.week4Day1Itchign = this.$route.params.temporalData.week4Day1Itchign;
      this.week4Day1Wheal = this.$route.params.temporalData.week4Day1Wheal;
      this.week4Day2Itchign = this.$route.params.temporalData.week4Day2Itchign;
      this.week4Day2Wheal = this.$route.params.temporalData.week4Day2Wheal;
      this.week4Day3Itchign = this.$route.params.temporalData.week4Day3Itchign;
      this.week4Day3Wheal = this.$route.params.temporalData.week4Day3Wheal;
      this.week4Day4Itchign = this.$route.params.temporalData.week4Day4Itchign;
      this.week4Day4Wheal = this.$route.params.temporalData.week4Day4Wheal;
      this.week4Day5Itchign = this.$route.params.temporalData.week4Day5Itchign;
      this.week4Day5Wheal = this.$route.params.temporalData.week4Day5Wheal;
      this.week4Day6Itchign = this.$route.params.temporalData.week4Day6Itchign;
      this.week4Day6Wheal = this.$route.params.temporalData.week4Day6Wheal;
      this.week4Day7Itchign = this.$route.params.temporalData.week4Day7Itchign;
      this.week4Day7Wheal = this.$route.params.temporalData.week4Day7Wheal;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return this.week1 + this.week2 + this.week3 + this.week4;
    },
    week1() {
      return (
        parseInt(this.week1Day1Itchign || 0) +
        parseInt(this.week1Day1Wheal || 0) +
        parseInt(this.week1Day2Itchign || 0) +
        parseInt(this.week1Day2Wheal || 0) +
        parseInt(this.week1Day3Itchign || 0) +
        parseInt(this.week1Day3Wheal || 0) +
        parseInt(this.week1Day4Itchign || 0) +
        parseInt(this.week1Day4Wheal || 0) +
        parseInt(this.week1Day5Itchign || 0) +
        parseInt(this.week1Day5Wheal || 0) +
        parseInt(this.week1Day6Itchign || 0) +
        parseInt(this.week1Day6Wheal || 0) +
        parseInt(this.week1Day7Itchign || 0) +
        parseInt(this.week1Day7Wheal || 0)
      );
    },
    week1Text() {
      let text = "";

      if (parseInt(this.week1) == 0) {
        text = "Asintomático";
      }

      if (parseInt(this.week1) >= 1 && parseInt(this.week1) <= 6) {
        text = "Adecuado control";
      }

      if (parseInt(this.week1) >= 7 && parseInt(this.week1) <= 15) {
        text = "Leve";
      }

      if (this.week1 >= 16 && this.week1 <= 27) {
        text = "Moderada";
      }

      if (this.week1 >= 28 && this.week1 <= 42) {
        text = "Severa";
      }

      return text;
    },
    week2() {
      return (
        parseInt(this.week2Day1Itchign || 0) +
        parseInt(this.week2Day1Wheal || 0) +
        parseInt(this.week2Day2Itchign || 0) +
        parseInt(this.week2Day2Wheal || 0) +
        parseInt(this.week2Day3Itchign || 0) +
        parseInt(this.week2Day3Wheal || 0) +
        parseInt(this.week2Day4Itchign || 0) +
        parseInt(this.week2Day4Wheal || 0) +
        parseInt(this.week2Day5Itchign || 0) +
        parseInt(this.week2Day5Wheal || 0) +
        parseInt(this.week2Day6Itchign || 0) +
        parseInt(this.week2Day6Wheal || 0) +
        parseInt(this.week2Day7Itchign || 0) +
        parseInt(this.week2Day7Wheal || 0)
      );
    },
    week2Text() {
      let text = "";

      if (parseInt(this.week2) == 0) {
        text = "Asintomático";
      }

      if (parseInt(this.week2) >= 1 && parseInt(this.week2) <= 6) {
        text = "Adecuado control";
      }

      if (parseInt(this.week2) >= 7 && parseInt(this.week2) <= 15) {
        text = "Leve";
      }

      if (this.week2 >= 16 && this.week2 <= 27) {
        text = "Moderada";
      }

      if (this.week2 >= 28 && this.week2 <= 42) {
        text = "Severa";
      }

      return text;
    },
    week3() {
      return (
        parseInt(this.week3Day1Itchign || 0) +
        parseInt(this.week3Day1Wheal || 0) +
        parseInt(this.week3Day2Itchign || 0) +
        parseInt(this.week3Day2Wheal || 0) +
        parseInt(this.week3Day3Itchign || 0) +
        parseInt(this.week3Day3Wheal || 0) +
        parseInt(this.week3Day4Itchign || 0) +
        parseInt(this.week3Day4Wheal || 0) +
        parseInt(this.week3Day5Itchign || 0) +
        parseInt(this.week3Day5Wheal || 0) +
        parseInt(this.week3Day6Itchign || 0) +
        parseInt(this.week3Day6Wheal || 0) +
        parseInt(this.week3Day7Itchign || 0) +
        parseInt(this.week3Day7Wheal || 0)
      );
    },
    week3Text() {
      let text = "";

      if (parseInt(this.week3) == 0) {
        text = "Asintomático";
      }

      if (parseInt(this.week3) >= 1 && parseInt(this.week3) <= 6) {
        text = "Adecuado control";
      }

      if (parseInt(this.week3) >= 7 && parseInt(this.week3) <= 15) {
        text = "Leve";
      }

      if (this.week3 >= 16 && this.week3 <= 27) {
        text = "Moderada";
      }

      if (this.week3 >= 28 && this.week3 <= 42) {
        text = "Severa";
      }

      return text;
    },
    week4() {
      return (
        parseInt(this.week4Day1Itchign || 0) +
        parseInt(this.week4Day1Wheal || 0) +
        parseInt(this.week4Day2Itchign || 0) +
        parseInt(this.week4Day2Wheal || 0) +
        parseInt(this.week4Day3Itchign || 0) +
        parseInt(this.week4Day3Wheal || 0) +
        parseInt(this.week4Day4Itchign || 0) +
        parseInt(this.week4Day4Wheal || 0) +
        parseInt(this.week4Day5Itchign || 0) +
        parseInt(this.week4Day5Wheal || 0) +
        parseInt(this.week4Day6Itchign || 0) +
        parseInt(this.week4Day6Wheal || 0) +
        parseInt(this.week4Day7Itchign || 0) +
        parseInt(this.week4Day7Wheal || 0)
      );
    },
    week4Text() {
      let text = "";

      if (parseInt(this.week4) == 0) {
        text = "Asintomático";
      }

      if (parseInt(this.week4) >= 1 && parseInt(this.week4) <= 6) {
        text = "Adecuado control";
      }

      if (parseInt(this.week4) >= 7 && parseInt(this.week4) <= 15) {
        text = "Leve";
      }

      if (this.week4 >= 16 && this.week4 <= 27) {
        text = "Moderada";
      }

      if (this.week4 >= 28 && this.week4 <= 42) {
        text = "Severa";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.week1Day1Itchign === "" ||
        this.week1Day1Wheal === "" ||
        this.week1Day2Itchign === "" ||
        this.week1Day2Wheal === "" ||
        this.week1Day3Itchign === "" ||
        this.week1Day3Wheal === "" ||
        this.week1Day4Itchign === "" ||
        this.week1Day4Wheal === "" ||
        this.week1Day5Itchign === "" ||
        this.week1Day5Wheal === "" ||
        this.week1Day6Itchign === "" ||
        this.week1Day6Wheal === "" ||
        this.week1Day7Itchign === "" ||
        this.week1Day7Wheal === "" ||
        this.week2Day1Itchign === "" ||
        this.week2Day1Wheal === "" ||
        this.week2Day2Itchign === "" ||
        this.week2Day2Wheal === "" ||
        this.week2Day3Itchign === "" ||
        this.week2Day3Wheal === "" ||
        this.week2Day4Itchign === "" ||
        this.week2Day4Wheal === "" ||
        this.week2Day5Itchign === "" ||
        this.week2Day5Wheal === "" ||
        this.week2Day6Itchign === "" ||
        this.week2Day6Wheal === "" ||
        this.week2Day7Itchign === "" ||
        this.week2Day7Wheal === "" ||
        this.week3Day1Itchign === "" ||
        this.week3Day1Wheal === "" ||
        this.week3Day2Itchign === "" ||
        this.week3Day2Wheal === "" ||
        this.week3Day3Itchign === "" ||
        this.week3Day3Wheal === "" ||
        this.week3Day4Itchign === "" ||
        this.week3Day4Wheal === "" ||
        this.week3Day5Itchign === "" ||
        this.week3Day5Wheal === "" ||
        this.week3Day6Itchign === "" ||
        this.week3Day6Wheal === "" ||
        this.week3Day7Itchign === "" ||
        this.week3Day7Wheal === "" ||
        this.week4Day1Itchign === "" ||
        this.week4Day1Wheal === "" ||
        this.week4Day2Itchign === "" ||
        this.week4Day2Wheal === "" ||
        this.week4Day3Itchign === "" ||
        this.week4Day3Wheal === "" ||
        this.week4Day4Itchign === "" ||
        this.week4Day4Wheal === "" ||
        this.week4Day5Itchign === "" ||
        this.week4Day5Wheal === "" ||
        this.week4Day6Itchign === "" ||
        this.week4Day6Wheal === "" ||
        this.week4Day7Itchign === "" ||
        this.week4Day7Wheal === ""
      );
    },
    isEmpty() {
      return (
        this.week1Day1Itchign === "" &&
        this.week1Day1Wheal === "" &&
        this.week1Day2Itchign === "" &&
        this.week1Day2Wheal === "" &&
        this.week1Day3Itchign === "" &&
        this.week1Day3Wheal === "" &&
        this.week1Day4Itchign === "" &&
        this.week1Day4Wheal === "" &&
        this.week1Day5Itchign === "" &&
        this.week1Day5Wheal === "" &&
        this.week1Day6Itchign === "" &&
        this.week1Day6Wheal === "" &&
        this.week1Day7Itchign === "" &&
        this.week1Day7Wheal === "" &&
        this.week2Day1Itchign === "" &&
        this.week2Day1Wheal === "" &&
        this.week2Day2Itchign === "" &&
        this.week2Day2Wheal === "" &&
        this.week2Day3Itchign === "" &&
        this.week2Day3Wheal === "" &&
        this.week2Day4Itchign === "" &&
        this.week2Day4Wheal === "" &&
        this.week2Day5Itchign === "" &&
        this.week2Day5Wheal === "" &&
        this.week2Day6Itchign === "" &&
        this.week2Day6Wheal === "" &&
        this.week2Day7Itchign === "" &&
        this.week2Day7Wheal === "" &&
        this.week3Day1Itchign === "" &&
        this.week3Day1Wheal === "" &&
        this.week3Day2Itchign === "" &&
        this.week3Day2Wheal === "" &&
        this.week3Day3Itchign === "" &&
        this.week3Day3Wheal === "" &&
        this.week3Day4Itchign === "" &&
        this.week3Day4Wheal === "" &&
        this.week3Day5Itchign === "" &&
        this.week3Day5Wheal === "" &&
        this.week3Day6Itchign === "" &&
        this.week3Day6Wheal === "" &&
        this.week3Day7Itchign === "" &&
        this.week3Day7Wheal === "" &&
        this.week4Day1Itchign === "" &&
        this.week4Day1Wheal === "" &&
        this.week4Day2Itchign === "" &&
        this.week4Day2Wheal === "" &&
        this.week4Day3Itchign === "" &&
        this.week4Day3Wheal === "" &&
        this.week4Day4Itchign === "" &&
        this.week4Day4Wheal === "" &&
        this.week4Day5Itchign === "" &&
        this.week4Day5Wheal === "" &&
        this.week4Day6Itchign === "" &&
        this.week4Day6Wheal === "" &&
        this.week4Day7Itchign === "" &&
        this.week4Day7Wheal === ""
      );
    },
    week1Incompleted() {
      return (
        this.week1Day1Itchign === "" ||
        this.week1Day1Wheal === "" ||
        this.week1Day2Itchign === "" ||
        this.week1Day2Wheal === "" ||
        this.week1Day3Itchign === "" ||
        this.week1Day3Wheal === "" ||
        this.week1Day4Itchign === "" ||
        this.week1Day4Wheal === "" ||
        this.week1Day5Itchign === "" ||
        this.week1Day5Wheal === "" ||
        this.week1Day6Itchign === "" ||
        this.week1Day6Wheal === "" ||
        this.week1Day7Itchign === "" ||
        this.week1Day7Wheal === ""
      );
    },
    week2Incompleted() {
      return (
        this.week2Day1Itchign === "" ||
        this.week2Day1Wheal === "" ||
        this.week2Day2Itchign === "" ||
        this.week2Day2Wheal === "" ||
        this.week2Day3Itchign === "" ||
        this.week2Day3Wheal === "" ||
        this.week2Day4Itchign === "" ||
        this.week2Day4Wheal === "" ||
        this.week2Day5Itchign === "" ||
        this.week2Day5Wheal === "" ||
        this.week2Day6Itchign === "" ||
        this.week2Day6Wheal === "" ||
        this.week2Day7Itchign === "" ||
        this.week2Day7Wheal === ""
      );
    },
    week3Incompleted() {
      return (
        this.week3Day1Itchign === "" ||
        this.week3Day1Wheal === "" ||
        this.week3Day2Itchign === "" ||
        this.week3Day2Wheal === "" ||
        this.week3Day3Itchign === "" ||
        this.week3Day3Wheal === "" ||
        this.week3Day4Itchign === "" ||
        this.week3Day4Wheal === "" ||
        this.week3Day5Itchign === "" ||
        this.week3Day5Wheal === "" ||
        this.week3Day6Itchign === "" ||
        this.week3Day6Wheal === "" ||
        this.week3Day7Itchign === "" ||
        this.week3Day7Wheal === ""
      );
    },
    week4Incompleted() {
      return (
        this.week4Day1Itchign === "" ||
        this.week4Day1Wheal === "" ||
        this.week4Day2Itchign === "" ||
        this.week4Day2Wheal === "" ||
        this.week4Day3Itchign === "" ||
        this.week4Day3Wheal === "" ||
        this.week4Day4Itchign === "" ||
        this.week4Day4Wheal === "" ||
        this.week4Day5Itchign === "" ||
        this.week4Day5Wheal === "" ||
        this.week4Day6Itchign === "" ||
        this.week4Day6Wheal === "" ||
        this.week4Day7Itchign === "" ||
        this.week4Day7Wheal === ""
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.week1Day1Itchign = "";
      this.week1Day1Wheal = "";
      this.week1Day2Itchign = "";
      this.week1Day2Wheal = "";
      this.week1Day3Itchign = "";
      this.week1Day3Wheal = "";
      this.week1Day4Itchign = "";
      this.week1Day4Wheal = "";
      this.week1Day5Itchign = "";
      this.week1Day5Wheal = "";
      this.week1Day6Itchign = "";
      this.week1Day6Wheal = "";
      this.week1Day7Itchign = "";
      this.week1Day7Wheal = "";
      this.week2Day1Itchign = "";
      this.week2Day1Wheal = "";
      this.week2Day2Itchign = "";
      this.week2Day2Wheal = "";
      this.week2Day3Itchign = "";
      this.week2Day3Wheal = "";
      this.week2Day4Itchign = "";
      this.week2Day4Wheal = "";
      this.week2Day5Itchign = "";
      this.week2Day5Wheal = "";
      this.week2Day6Itchign = "";
      this.week2Day6Wheal = "";
      this.week2Day7Itchign = "";
      this.week2Day7Wheal = "";
      this.week3Day1Itchign = "";
      this.week3Day1Wheal = "";
      this.week3Day2Itchign = "";
      this.week3Day2Wheal = "";
      this.week3Day3Itchign = "";
      this.week3Day3Wheal = "";
      this.week3Day4Itchign = "";
      this.week3Day4Wheal = "";
      this.week3Day5Itchign = "";
      this.week3Day5Wheal = "";
      this.week3Day6Itchign = "";
      this.week3Day6Wheal = "";
      this.week3Day7Itchign = "";
      this.week3Day7Wheal = "";
      this.week4Day1Itchign = "";
      this.week4Day1Wheal = "";
      this.week4Day2Itchign = "";
      this.week4Day2Wheal = "";
      this.week4Day3Itchign = "";
      this.week4Day3Wheal = "";
      this.week4Day4Itchign = "";
      this.week4Day4Wheal = "";
      this.week4Day5Itchign = "";
      this.week4Day5Wheal = "";
      this.week4Day6Itchign = "";
      this.week4Day6Wheal = "";
      this.week4Day7Itchign = "";
      this.week4Day7Wheal = "";
      this.activeStep = 0;
      this.$global.$emit("clearForm");
    },
    clear() {
      this.restoreData.week1Day1Itchign = this.week1Day1Itchign;
      this.restoreData.week1Day1Wheal = this.week1Day1Wheal;
      this.restoreData.week1Day2Itchign = this.week1Day2Itchign;
      this.restoreData.week1Day2Wheal = this.week1Day2Wheal;
      this.restoreData.week1Day3Itchign = this.week1Day3Itchign;
      this.restoreData.week1Day3Wheal = this.week1Day3Wheal;
      this.restoreData.week1Day4Itchign = this.week1Day4Itchign;
      this.restoreData.week1Day4Wheal = this.week1Day4Wheal;
      this.restoreData.week1Day5Itchign = this.week1Day5Itchign;
      this.restoreData.week1Day5Wheal = this.week1Day5Wheal;
      this.restoreData.week1Day6Itchign = this.week1Day6Itchign;
      this.restoreData.week1Day6Wheal = this.week1Day6Wheal;
      this.restoreData.week1Day7Itchign = this.week1Day7Itchign;
      this.restoreData.week1Day7Wheal = this.week1Day7Wheal;
      this.restoreData.week2Day1Itchign = this.week2Day1Itchign;
      this.restoreData.week2Day1Wheal = this.week2Day1Wheal;
      this.restoreData.week2Day2Itchign = this.week2Day2Itchign;
      this.restoreData.week2Day2Wheal = this.week2Day2Wheal;
      this.restoreData.week2Day3Itchign = this.week2Day3Itchign;
      this.restoreData.week2Day3Wheal = this.week2Day3Wheal;
      this.restoreData.week2Day4Itchign = this.week2Day4Itchign;
      this.restoreData.week2Day4Wheal = this.week2Day4Wheal;
      this.restoreData.week2Day5Itchign = this.week2Day5Itchign;
      this.restoreData.week2Day5Wheal = this.week2Day5Wheal;
      this.restoreData.week2Day6Itchign = this.week2Day6Itchign;
      this.restoreData.week2Day6Wheal = this.week2Day6Wheal;
      this.restoreData.week2Day7Itchign = this.week2Day7Itchign;
      this.restoreData.week2Day7Wheal = this.week2Day7Wheal;
      this.restoreData.week3Day1Itchign = this.week3Day1Itchign;
      this.restoreData.week3Day1Wheal = this.week3Day1Wheal;
      this.restoreData.week3Day2Itchign = this.week3Day2Itchign;
      this.restoreData.week3Day2Wheal = this.week3Day2Wheal;
      this.restoreData.week3Day3Itchign = this.week3Day3Itchign;
      this.restoreData.week3Day3Wheal = this.week3Day3Wheal;
      this.restoreData.week3Day4Itchign = this.week3Day4Itchign;
      this.restoreData.week3Day4Wheal = this.week3Day4Wheal;
      this.restoreData.week3Day5Itchign = this.week3Day5Itchign;
      this.restoreData.week3Day5Wheal = this.week3Day5Wheal;
      this.restoreData.week3Day6Itchign = this.week3Day6Itchign;
      this.restoreData.week3Day6Wheal = this.week3Day6Wheal;
      this.restoreData.week3Day7Itchign = this.week3Day7Itchign;
      this.restoreData.week3Day7Wheal = this.week3Day7Wheal;
      this.restoreData.week4Day1Itchign = this.week4Day1Itchign;
      this.restoreData.week4Day1Wheal = this.week4Day1Wheal;
      this.restoreData.week4Day2Itchign = this.week4Day2Itchign;
      this.restoreData.week4Day2Wheal = this.week4Day2Wheal;
      this.restoreData.week4Day3Itchign = this.week4Day3Itchign;
      this.restoreData.week4Day3Wheal = this.week4Day3Wheal;
      this.restoreData.week4Day4Itchign = this.week4Day4Itchign;
      this.restoreData.week4Day4Wheal = this.week4Day4Wheal;
      this.restoreData.week4Day5Itchign = this.week4Day5Itchign;
      this.restoreData.week4Day5Wheal = this.week4Day5Wheal;
      this.restoreData.week4Day6Itchign = this.week4Day6Itchign;
      this.restoreData.week4Day6Wheal = this.week4Day6Wheal;
      this.restoreData.week4Day7Itchign = this.week4Day7Itchign;
      this.restoreData.week4Day7Wheal = this.week4Day7Wheal;

      this.cleanForms();
      this.clearTemporalSurveys();
      this.showUndoDialog("Se limpiaron los datos de  la encuesta UAS7.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);

        let data = {
          surveyCode: "UAS7",
          result: String(this.score),
          appreciation: "",
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let week1Day1WhealAns = null;
      let week1Day1ItchignAns = null;
      let week1Day2ItchignAns = null;
      let week1Day2WhealAns = null;
      let week1Day3ItchignAns = null;
      let week1Day3WhealAns = null;
      let week1Day4ItchignAns = null;
      let week1Day4WhealAns = null;
      let week1Day5ItchignAns = null;
      let week1Day5WhealAns = null;
      let week1Day6ItchignAns = null;
      let week1Day6WhealAns = null;
      let week1Day7ItchignAns = null;
      let week1Day7WhealAns = null;
      let week2Day1ItchignAns = null;
      let week2Day1WhealAns = null;
      let week2Day2ItchignAns = null;
      let week2Day2WhealAns = null;
      let week2Day3ItchignAns = null;
      let week2Day3WhealAns = null;
      let week2Day4ItchignAns = null;
      let week2Day4WhealAns = null;
      let week2Day5ItchignAns = null;
      let week2Day5WhealAns = null;
      let week2Day6ItchignAns = null;
      let week2Day6WhealAns = null;
      let week2Day7ItchignAns = null;
      let week2Day7WhealAns = null;
      let week3Day1ItchignAns = null;
      let week3Day1WhealAns = null;
      let week3Day2ItchignAns = null;
      let week3Day2WhealAns = null;
      let week3Day3ItchignAns = null;
      let week3Day3WhealAns = null;
      let week3Day4ItchignAns = null;
      let week3Day4WhealAns = null;
      let week3Day5ItchignAns = null;
      let week3Day5WhealAns = null;
      let week3Day6ItchignAns = null;
      let week3Day6WhealAns = null;
      let week3Day7ItchignAns = null;
      let week3Day7WhealAns = null;
      let week4Day1ItchignAns = null;
      let week4Day1WhealAns = null;
      let week4Day2ItchignAns = null;
      let week4Day2WhealAns = null;
      let week4Day3ItchignAns = null;
      let week4Day3WhealAns = null;
      let week4Day4ItchignAns = null;
      let week4Day4WhealAns = null;
      let week4Day5ItchignAns = null;
      let week4Day5WhealAns = null;
      let week4Day6ItchignAns = null;
      let week4Day6WhealAns = null;
      let week4Day7ItchignAns = null;
      let week4Day7WhealAns = null;

      Object.entries(this.answers.SEMANA1.DIA1.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day1Wheal) {
          week1Day1WhealAns = {
            questionCode: this.questions.SEMANA1.DIA1
              .UAS7_SEMANA_1_DIA1_RONCHAS,
            answerCode: key[0],
            value: this.week1Day1Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA2.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day2Wheal) {
          week1Day2WhealAns = {
            questionCode: this.questions.SEMANA1.DIA2
              .UAS7_SEMANA_1_DIA2_RONCHAS,
            answerCode: key[0],
            value: this.week1Day2Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day3Wheal) {
          week1Day3WhealAns = {
            questionCode: this.questions.SEMANA1.DIA3
              .UAS7_SEMANA_1_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week1Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day3Wheal) {
          week1Day3WhealAns = {
            questionCode: this.questions.SEMANA1.DIA3
              .UAS7_SEMANA_1_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week1Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA4.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day4Wheal) {
          week1Day4WhealAns = {
            questionCode: this.questions.SEMANA1.DIA4
              .UAS7_SEMANA_1_DIA4_RONCHAS,
            answerCode: key[0],
            value: this.week1Day4Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA5.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day5Wheal) {
          week1Day5WhealAns = {
            questionCode: this.questions.SEMANA1.DIA5
              .UAS7_SEMANA_1_DIA5_RONCHAS,
            answerCode: key[0],
            value: this.week1Day5Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA6.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day6Wheal) {
          week1Day6WhealAns = {
            questionCode: this.questions.SEMANA1.DIA6
              .UAS7_SEMANA_1_DIA6_RONCHAS,
            answerCode: key[0],
            value: this.week1Day6Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA7.RONCHAS).forEach((key) => {
        if (key[1] == this.week1Day7Wheal) {
          week1Day7WhealAns = {
            questionCode: this.questions.SEMANA1.DIA7
              .UAS7_SEMANA_1_DIA7_RONCHAS,
            answerCode: key[0],
            value: this.week1Day7Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA1.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day1Wheal) {
          week2Day1WhealAns = {
            questionCode: this.questions.SEMANA2.DIA1
              .UAS7_SEMANA_2_DIA1_RONCHAS,
            answerCode: key[0],
            value: this.week2Day1Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA2.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day2Wheal) {
          week2Day2WhealAns = {
            questionCode: this.questions.SEMANA2.DIA2
              .UAS7_SEMANA_2_DIA2_RONCHAS,
            answerCode: key[0],
            value: this.week2Day2Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day3Wheal) {
          week2Day3WhealAns = {
            questionCode: this.questions.SEMANA2.DIA3
              .UAS7_SEMANA_2_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week2Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day3Wheal) {
          week2Day3WhealAns = {
            questionCode: this.questions.SEMANA2.DIA3
              .UAS7_SEMANA_2_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week2Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA4.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day4Wheal) {
          week2Day4WhealAns = {
            questionCode: this.questions.SEMANA2.DIA4
              .UAS7_SEMANA_2_DIA4_RONCHAS,
            answerCode: key[0],
            value: this.week2Day4Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA5.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day5Wheal) {
          week2Day5WhealAns = {
            questionCode: this.questions.SEMANA2.DIA5
              .UAS7_SEMANA_2_DIA5_RONCHAS,
            answerCode: key[0],
            value: this.week2Day5Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA6.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day6Wheal) {
          week2Day6WhealAns = {
            questionCode: this.questions.SEMANA2.DIA6
              .UAS7_SEMANA_2_DIA6_RONCHAS,
            answerCode: key[0],
            value: this.week2Day6Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA7.RONCHAS).forEach((key) => {
        if (key[1] == this.week2Day7Wheal) {
          week2Day7WhealAns = {
            questionCode: this.questions.SEMANA2.DIA7
              .UAS7_SEMANA_2_DIA7_RONCHAS,
            answerCode: key[0],
            value: this.week2Day7Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA1.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day1Wheal) {
          week3Day1WhealAns = {
            questionCode: this.questions.SEMANA3.DIA1
              .UAS7_SEMANA_3_DIA1_RONCHAS,
            answerCode: key[0],
            value: this.week3Day1Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA2.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day2Wheal) {
          week3Day2WhealAns = {
            questionCode: this.questions.SEMANA3.DIA2
              .UAS7_SEMANA_3_DIA2_RONCHAS,
            answerCode: key[0],
            value: this.week3Day2Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day3Wheal) {
          week3Day3WhealAns = {
            questionCode: this.questions.SEMANA3.DIA3
              .UAS7_SEMANA_3_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week3Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day3Wheal) {
          week3Day3WhealAns = {
            questionCode: this.questions.SEMANA3.DIA3
              .UAS7_SEMANA_3_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week3Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA4.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day4Wheal) {
          week3Day4WhealAns = {
            questionCode: this.questions.SEMANA3.DIA4
              .UAS7_SEMANA_3_DIA4_RONCHAS,
            answerCode: key[0],
            value: this.week3Day4Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA5.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day5Wheal) {
          week3Day5WhealAns = {
            questionCode: this.questions.SEMANA3.DIA5
              .UAS7_SEMANA_3_DIA5_RONCHAS,
            answerCode: key[0],
            value: this.week3Day5Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA6.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day6Wheal) {
          week3Day6WhealAns = {
            questionCode: this.questions.SEMANA3.DIA6
              .UAS7_SEMANA_3_DIA6_RONCHAS,
            answerCode: key[0],
            value: this.week3Day6Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA7.RONCHAS).forEach((key) => {
        if (key[1] == this.week3Day7Wheal) {
          week3Day7WhealAns = {
            questionCode: this.questions.SEMANA3.DIA7
              .UAS7_SEMANA_3_DIA7_RONCHAS,
            answerCode: key[0],
            value: this.week3Day7Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA1.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day1Wheal) {
          week4Day1WhealAns = {
            questionCode: this.questions.SEMANA4.DIA1
              .UAS7_SEMANA_4_DIA1_RONCHAS,
            answerCode: key[0],
            value: this.week4Day1Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA2.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day2Wheal) {
          week4Day2WhealAns = {
            questionCode: this.questions.SEMANA4.DIA2
              .UAS7_SEMANA_4_DIA2_RONCHAS,
            answerCode: key[0],
            value: this.week4Day2Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day3Wheal) {
          week4Day3WhealAns = {
            questionCode: this.questions.SEMANA4.DIA3
              .UAS7_SEMANA_4_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week4Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA3.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day3Wheal) {
          week4Day3WhealAns = {
            questionCode: this.questions.SEMANA4.DIA3
              .UAS7_SEMANA_4_DIA3_RONCHAS,
            answerCode: key[0],
            value: this.week4Day3Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA4.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day4Wheal) {
          week4Day4WhealAns = {
            questionCode: this.questions.SEMANA4.DIA4
              .UAS7_SEMANA_4_DIA4_RONCHAS,
            answerCode: key[0],
            value: this.week4Day4Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA5.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day5Wheal) {
          week4Day5WhealAns = {
            questionCode: this.questions.SEMANA4.DIA5
              .UAS7_SEMANA_4_DIA5_RONCHAS,
            answerCode: key[0],
            value: this.week4Day5Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA6.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day6Wheal) {
          week4Day6WhealAns = {
            questionCode: this.questions.SEMANA4.DIA6
              .UAS7_SEMANA_4_DIA6_RONCHAS,
            answerCode: key[0],
            value: this.week4Day6Wheal,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA7.RONCHAS).forEach((key) => {
        if (key[1] == this.week4Day7Wheal) {
          week4Day7WhealAns = {
            questionCode: this.questions.SEMANA4.DIA7
              .UAS7_SEMANA_4_DIA7_RONCHAS,
            answerCode: key[0],
            value: this.week4Day7Wheal,
          };
        }
      });

      /**************************** */

      Object.entries(this.answers.SEMANA1.DIA1.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day1Itchign) {
          week1Day1ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA1
              .UAS7_SEMANA_1_DIA1_PICAZON,
            answerCode: key[0],
            value: this.week1Day1Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA2.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day2Itchign) {
          week1Day2ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA2
              .UAS7_SEMANA_1_DIA2_PICAZON,
            answerCode: key[0],
            value: this.week1Day2Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day3Itchign) {
          week1Day3ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA3
              .UAS7_SEMANA_1_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week1Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day3Itchign) {
          week1Day3ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA3
              .UAS7_SEMANA_1_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week1Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA4.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day4Itchign) {
          week1Day4ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA4
              .UAS7_SEMANA_1_DIA4_PICAZON,
            answerCode: key[0],
            value: this.week1Day4Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA5.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day5Itchign) {
          week1Day5ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA5
              .UAS7_SEMANA_1_DIA5_PICAZON,
            answerCode: key[0],
            value: this.week1Day5Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA6.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day6Itchign) {
          week1Day6ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA6
              .UAS7_SEMANA_1_DIA6_PICAZON,
            answerCode: key[0],
            value: this.week1Day6Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA1.DIA7.PICAZON).forEach((key) => {
        if (key[1] == this.week1Day7Itchign) {
          week1Day7ItchignAns = {
            questionCode: this.questions.SEMANA1.DIA7
              .UAS7_SEMANA_1_DIA7_PICAZON,
            answerCode: key[0],
            value: this.week1Day7Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA1.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day1Itchign) {
          week2Day1ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA1
              .UAS7_SEMANA_2_DIA1_PICAZON,
            answerCode: key[0],
            value: this.week2Day1Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA2.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day2Itchign) {
          week2Day2ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA2
              .UAS7_SEMANA_2_DIA2_PICAZON,
            answerCode: key[0],
            value: this.week2Day2Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day3Itchign) {
          week2Day3ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA3
              .UAS7_SEMANA_2_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week2Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day3Itchign) {
          week2Day3ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA3
              .UAS7_SEMANA_2_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week2Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA4.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day4Itchign) {
          week2Day4ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA4
              .UAS7_SEMANA_2_DIA4_PICAZON,
            answerCode: key[0],
            value: this.week2Day4Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA5.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day5Itchign) {
          week2Day5ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA5
              .UAS7_SEMANA_2_DIA5_PICAZON,
            answerCode: key[0],
            value: this.week2Day5Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA6.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day6Itchign) {
          week2Day6ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA6
              .UAS7_SEMANA_2_DIA6_PICAZON,
            answerCode: key[0],
            value: this.week2Day6Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA2.DIA7.PICAZON).forEach((key) => {
        if (key[1] == this.week2Day7Itchign) {
          week2Day7ItchignAns = {
            questionCode: this.questions.SEMANA2.DIA7
              .UAS7_SEMANA_2_DIA7_PICAZON,
            answerCode: key[0],
            value: this.week2Day7Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA1.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day1Itchign) {
          week3Day1ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA1
              .UAS7_SEMANA_3_DIA1_PICAZON,
            answerCode: key[0],
            value: this.week3Day1Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA2.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day2Itchign) {
          week3Day2ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA2
              .UAS7_SEMANA_3_DIA2_PICAZON,
            answerCode: key[0],
            value: this.week3Day2Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day3Itchign) {
          week3Day3ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA3
              .UAS7_SEMANA_3_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week3Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day3Itchign) {
          week3Day3ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA3
              .UAS7_SEMANA_3_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week3Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA4.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day4Itchign) {
          week3Day4ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA4
              .UAS7_SEMANA_3_DIA4_PICAZON,
            answerCode: key[0],
            value: this.week3Day4Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA5.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day5Itchign) {
          week3Day5ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA5
              .UAS7_SEMANA_3_DIA5_PICAZON,
            answerCode: key[0],
            value: this.week3Day5Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA6.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day6Itchign) {
          week3Day6ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA6
              .UAS7_SEMANA_3_DIA6_PICAZON,
            answerCode: key[0],
            value: this.week3Day6Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA3.DIA7.PICAZON).forEach((key) => {
        if (key[1] == this.week3Day7Itchign) {
          week3Day7ItchignAns = {
            questionCode: this.questions.SEMANA3.DIA7
              .UAS7_SEMANA_3_DIA7_PICAZON,
            answerCode: key[0],
            value: this.week3Day7Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA1.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day1Itchign) {
          week4Day1ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA1
              .UAS7_SEMANA_4_DIA1_PICAZON,
            answerCode: key[0],
            value: this.week4Day1Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA2.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day2Itchign) {
          week4Day2ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA2
              .UAS7_SEMANA_4_DIA2_PICAZON,
            answerCode: key[0],
            value: this.week4Day2Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day3Itchign) {
          week4Day3ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA3
              .UAS7_SEMANA_4_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week4Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA3.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day3Itchign) {
          week4Day3ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA3
              .UAS7_SEMANA_4_DIA3_PICAZON,
            answerCode: key[0],
            value: this.week4Day3Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA4.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day4Itchign) {
          week4Day4ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA4
              .UAS7_SEMANA_4_DIA4_PICAZON,
            answerCode: key[0],
            value: this.week4Day4Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA5.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day5Itchign) {
          week4Day5ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA5
              .UAS7_SEMANA_4_DIA5_PICAZON,
            answerCode: key[0],
            value: this.week4Day5Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA6.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day6Itchign) {
          week4Day6ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA6
              .UAS7_SEMANA_4_DIA6_PICAZON,
            answerCode: key[0],
            value: this.week4Day6Itchign,
          };
        }
      });

      Object.entries(this.answers.SEMANA4.DIA7.PICAZON).forEach((key) => {
        if (key[1] == this.week4Day7Itchign) {
          week4Day7ItchignAns = {
            questionCode: this.questions.SEMANA4.DIA7
              .UAS7_SEMANA_4_DIA7_PICAZON,
            answerCode: key[0],
            value: this.week4Day7Itchign,
          };
        }
      });

      return [
        week1Day1WhealAns,
        week1Day1ItchignAns,
        week1Day2ItchignAns,
        week1Day2WhealAns,
        week1Day3ItchignAns,
        week1Day3WhealAns,
        week1Day4ItchignAns,
        week1Day4WhealAns,
        week1Day5ItchignAns,
        week1Day5WhealAns,
        week1Day6ItchignAns,
        week1Day6WhealAns,
        week1Day7ItchignAns,
        week1Day7WhealAns,
        week2Day1ItchignAns,
        week2Day1WhealAns,
        week2Day2ItchignAns,
        week2Day2WhealAns,
        week2Day3ItchignAns,
        week2Day3WhealAns,
        week2Day4ItchignAns,
        week2Day4WhealAns,
        week2Day5ItchignAns,
        week2Day5WhealAns,
        week2Day6ItchignAns,
        week2Day6WhealAns,
        week2Day7ItchignAns,
        week2Day7WhealAns,
        week3Day1ItchignAns,
        week3Day1WhealAns,
        week3Day2ItchignAns,
        week3Day2WhealAns,
        week3Day3ItchignAns,
        week3Day3WhealAns,
        week3Day4ItchignAns,
        week3Day4WhealAns,
        week3Day5ItchignAns,
        week3Day5WhealAns,
        week3Day6ItchignAns,
        week3Day6WhealAns,
        week3Day7ItchignAns,
        week3Day7WhealAns,
        week4Day1ItchignAns,
        week4Day1WhealAns,
        week4Day2ItchignAns,
        week4Day2WhealAns,
        week4Day3ItchignAns,
        week4Day3WhealAns,
        week4Day4ItchignAns,
        week4Day4WhealAns,
        week4Day5ItchignAns,
        week4Day5WhealAns,
        week4Day6ItchignAns,
        week4Day6WhealAns,
        week4Day7ItchignAns,
        week4Day7WhealAns,
      ];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.week1Day1Itchign = this.restoreData.week1Day1Itchign;
      this.week1Day1Wheal = this.restoreData.week1Day1Wheal;
      this.week1Day2Itchign = this.restoreData.week1Day2Itchign;
      this.week1Day2Wheal = this.restoreData.week1Day2Wheal;
      this.week1Day3Itchign = this.restoreData.week1Day3Itchign;
      this.week1Day3Wheal = this.restoreData.week1Day3Wheal;
      this.week1Day4Itchign = this.restoreData.week1Day4Itchign;
      this.week1Day4Wheal = this.restoreData.week1Day4Wheal;
      this.week1Day5Itchign = this.restoreData.week1Day5Itchign;
      this.week1Day5Wheal = this.restoreData.week1Day5Wheal;
      this.week1Day6Itchign = this.restoreData.week1Day6Itchign;
      this.week1Day6Wheal = this.restoreData.week1Day6Wheal;
      this.week1Day7Itchign = this.restoreData.week1Day7Itchign;
      this.week1Day7Wheal = this.restoreData.week1Day7Wheal;
      this.week2Day1Itchign = this.restoreData.week2Day1Itchign;
      this.week2Day1Wheal = this.restoreData.week2Day1Wheal;
      this.week2Day2Itchign = this.restoreData.week2Day2Itchign;
      this.week2Day2Wheal = this.restoreData.week2Day2Wheal;
      this.week2Day3Itchign = this.restoreData.week2Day3Itchign;
      this.week2Day3Wheal = this.restoreData.week2Day3Wheal;
      this.week2Day4Itchign = this.restoreData.week2Day4Itchign;
      this.week2Day4Wheal = this.restoreData.week2Day4Wheal;
      this.week2Day5Itchign = this.restoreData.week2Day5Itchign;
      this.week2Day5Wheal = this.restoreData.week2Day5Wheal;
      this.week2Day6Itchign = this.restoreData.week2Day6Itchign;
      this.week2Day6Wheal = this.restoreData.week2Day6Wheal;
      this.week2Day7Itchign = this.restoreData.week2Day7Itchign;
      this.week2Day7Wheal = this.restoreData.week2Day7Wheal;
      this.week3Day1Itchign = this.restoreData.week3Day1Itchign;
      this.week3Day1Wheal = this.restoreData.week3Day1Wheal;
      this.week3Day2Itchign = this.restoreData.week3Day2Itchign;
      this.week3Day2Wheal = this.restoreData.week3Day2Wheal;
      this.week3Day3Itchign = this.restoreData.week3Day3Itchign;
      this.week3Day3Wheal = this.restoreData.week3Day3Wheal;
      this.week3Day4Itchign = this.restoreData.week3Day4Itchign;
      this.week3Day4Wheal = this.restoreData.week3Day4Wheal;
      this.week3Day5Itchign = this.restoreData.week3Day5Itchign;
      this.week3Day5Wheal = this.restoreData.week3Day5Wheal;
      this.week3Day6Itchign = this.restoreData.week3Day6Itchign;
      this.week3Day6Wheal = this.restoreData.week3Day6Wheal;
      this.week3Day7Itchign = this.restoreData.week3Day7Itchign;
      this.week3Day7Wheal = this.restoreData.week3Day7Wheal;
      this.week4Day1Itchign = this.restoreData.week4Day1Itchign;
      this.week4Day1Wheal = this.restoreData.week4Day1Wheal;
      this.week4Day2Itchign = this.restoreData.week4Day2Itchign;
      this.week4Day2Wheal = this.restoreData.week4Day2Wheal;
      this.week4Day3Itchign = this.restoreData.week4Day3Itchign;
      this.week4Day3Wheal = this.restoreData.week4Day3Wheal;
      this.week4Day4Itchign = this.restoreData.week4Day4Itchign;
      this.week4Day4Wheal = this.restoreData.week4Day4Wheal;
      this.week4Day5Itchign = this.restoreData.week4Day5Itchign;
      this.week4Day5Wheal = this.restoreData.week4Day5Wheal;
      this.week4Day6Itchign = this.restoreData.week4Day6Itchign;
      this.week4Day6Wheal = this.restoreData.week4Day6Wheal;
      this.week4Day7Itchign = this.restoreData.week4Day7Itchign;
      this.week4Day7Wheal = this.restoreData.week4Day7Wheal;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      if (!this.isEmpty) {
        this.$session.set("temporalSurvey", {
          module: {
            name: "UAS7",
            route: "Uas7",
          },
          survey: {
            week1Day1Itchign: this.week1Day1Itchign,
            week1Day1Wheal: this.week1Day1Wheal,
            week1Day2Itchign: this.week1Day2Itchign,
            week1Day2Wheal: this.week1Day2Wheal,
            week1Day3Itchign: this.week1Day3Itchign,
            week1Day3Wheal: this.week1Day3Wheal,
            week1Day4Itchign: this.week1Day4Itchign,
            week1Day4Wheal: this.week1Day4Wheal,
            week1Day5Itchign: this.week1Day5Itchign,
            week1Day5Wheal: this.week1Day5Wheal,
            week1Day6Itchign: this.week1Day6Itchign,
            week1Day6Wheal: this.week1Day6Wheal,
            week1Day7Itchign: this.week1Day7Itchign,
            week1Day7Wheal: this.week1Day7Wheal,
            week2Day1Itchign: this.week2Day1Itchign,
            week2Day1Wheal: this.week2Day1Wheal,
            week2Day2Itchign: this.week2Day2Itchign,
            week2Day2Wheal: this.week2Day2Wheal,
            week2Day3Itchign: this.week2Day3Itchign,
            week2Day3Wheal: this.week2Day3Wheal,
            week2Day4Itchign: this.week2Day4Itchign,
            week2Day4Wheal: this.week2Day4Wheal,
            week2Day5Itchign: this.week2Day5Itchign,
            week2Day5Wheal: this.week2Day5Wheal,
            week2Day6Itchign: this.week2Day6Itchign,
            week2Day6Wheal: this.week2Day6Wheal,
            week2Day7Itchign: this.week2Day7Itchign,
            week2Day7Wheal: this.week2Day7Wheal,
            week3Day1Itchign: this.week3Day1Itchign,
            week3Day1Wheal: this.week3Day1Wheal,
            week3Day2Itchign: this.week3Day2Itchign,
            week3Day2Wheal: this.week3Day2Wheal,
            week3Day3Itchign: this.week3Day3Itchign,
            week3Day3Wheal: this.week3Day3Wheal,
            week3Day4Itchign: this.week3Day4Itchign,
            week3Day4Wheal: this.week3Day4Wheal,
            week3Day5Itchign: this.week3Day5Itchign,
            week3Day5Wheal: this.week3Day5Wheal,
            week3Day6Itchign: this.week3Day6Itchign,
            week3Day6Wheal: this.week3Day6Wheal,
            week3Day7Itchign: this.week3Day7Itchign,
            week3Day7Wheal: this.week3Day7Wheal,
            week4Day1Itchign: this.week4Day1Itchign,
            week4Day1Wheal: this.week4Day1Wheal,
            week4Day2Itchign: this.week4Day2Itchign,
            week4Day2Wheal: this.week4Day2Wheal,
            week4Day3Itchign: this.week4Day3Itchign,
            week4Day3Wheal: this.week4Day3Wheal,
            week4Day4Itchign: this.week4Day4Itchign,
            week4Day4Wheal: this.week4Day4Wheal,
            week4Day5Itchign: this.week4Day5Itchign,
            week4Day5Wheal: this.week4Day5Wheal,
            week4Day6Itchign: this.week4Day6Itchign,
            week4Day6Wheal: this.week4Day6Wheal,
            week4Day7Itchign: this.week4Day7Itchign,
            week4Day7Wheal: this.week4Day7Wheal,
          },
        });
      }
    },
    setEvaluationAnswers(data) {
      this.result = data.result;
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "UAS7_SEMANA_1_DIA1_RONCHAS") {
          this.week1Day1Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA1_PICAZON") {
          this.week1Day1Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA2_RONCHAS") {
          this.week1Day2Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA2_PICAZON") {
          this.week1Day2Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA3_RONCHAS") {
          this.week1Day3Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA3_PICAZON") {
          this.week1Day3Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA4_RONCHAS") {
          this.week1Day4Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA4_PICAZON") {
          this.week1Day4Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA5_RONCHAS") {
          this.week1Day5Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA5_PICAZON") {
          this.week1Day5Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA6_RONCHAS") {
          this.week1Day6Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA6_PICAZON") {
          this.week1Day6Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA7_RONCHAS") {
          this.week1Day7Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_1_DIA7_PICAZON") {
          this.week1Day7Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA1_RONCHAS") {
          this.week2Day1Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA1_PICAZON") {
          this.week2Day1Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA2_RONCHAS") {
          this.week2Day2Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA2_PICAZON") {
          this.week2Day2Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA3_RONCHAS") {
          this.week2Day3Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA3_PICAZON") {
          this.week2Day3Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA4_RONCHAS") {
          this.week2Day4Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA4_PICAZON") {
          this.week2Day4Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA5_RONCHAS") {
          this.week2Day5Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA5_PICAZON") {
          this.week2Day5Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA6_RONCHAS") {
          this.week2Day6Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA6_PICAZON") {
          this.week2Day6Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA7_RONCHAS") {
          this.week2Day7Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_2_DIA7_PICAZON") {
          this.week2Day7Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA1_RONCHAS") {
          this.week3Day1Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA1_PICAZON") {
          this.week3Day1Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA2_RONCHAS") {
          this.week3Day2Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA2_PICAZON") {
          this.week3Day2Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA3_RONCHAS") {
          this.week3Day3Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA3_PICAZON") {
          this.week3Day3Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA4_RONCHAS") {
          this.week3Day4Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA4_PICAZON") {
          this.week3Day4Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA5_RONCHAS") {
          this.week3Day5Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA5_PICAZON") {
          this.week3Day5Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA6_RONCHAS") {
          this.week3Day6Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA6_PICAZON") {
          this.week3Day6Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA7_RONCHAS") {
          this.week3Day7Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_3_DIA7_PICAZON") {
          this.week3Day7Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA1_RONCHAS") {
          this.week4Day1Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA1_PICAZON") {
          this.week4Day1Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA2_RONCHAS") {
          this.week4Day2Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA2_PICAZON") {
          this.week4Day2Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA3_RONCHAS") {
          this.week4Day3Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA3_PICAZON") {
          this.week4Day3Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA4_RONCHAS") {
          this.week4Day4Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA4_PICAZON") {
          this.week4Day4Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA5_RONCHAS") {
          this.week4Day5Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA5_PICAZON") {
          this.week4Day5Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA6_RONCHAS") {
          this.week4Day6Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA6_PICAZON") {
          this.week4Day6Itchign = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA7_RONCHAS") {
          this.week4Day7Wheal = survey.value;
        }

        if (survey.question.code == "UAS7_SEMANA_4_DIA7_PICAZON") {
          this.week4Day7Itchign = survey.value;
        }
      });
    },
    initialAnswer() {
      this.week1Day1Itchign = this.answers.SEMANA1.DIA1.PICAZON.UAS7_SEMANA_1_DIA1_PICAZON_R1;
      this.week1Day1Wheal = this.answers.SEMANA1.DIA1.RONCHAS.UAS7_SEMANA_1_DIA1_RONCHAS_R1;
      this.week1Day2Itchign = this.answers.SEMANA1.DIA2.PICAZON.UAS7_SEMANA_1_DIA2_PICAZON_R1;
      this.week1Day2Wheal = this.answers.SEMANA1.DIA2.RONCHAS.UAS7_SEMANA_1_DIA2_RONCHAS_R1;
      this.week1Day3Itchign = this.answers.SEMANA1.DIA3.PICAZON.UAS7_SEMANA_1_DIA3_PICAZON_R1;
      this.week1Day3Wheal = this.answers.SEMANA1.DIA3.RONCHAS.UAS7_SEMANA_1_DIA3_RONCHAS_R1;
      this.week1Day4Itchign = this.answers.SEMANA1.DIA4.PICAZON.UAS7_SEMANA_1_DIA4_PICAZON_R1;
      this.week1Day4Wheal = this.answers.SEMANA1.DIA4.RONCHAS.UAS7_SEMANA_1_DIA4_RONCHAS_R1;
      this.week1Day5Itchign = this.answers.SEMANA1.DIA5.PICAZON.UAS7_SEMANA_1_DIA5_PICAZON_R1;
      this.week1Day5Wheal = this.answers.SEMANA1.DIA5.RONCHAS.UAS7_SEMANA_1_DIA5_RONCHAS_R1;
      this.week1Day6Itchign = this.answers.SEMANA1.DIA6.PICAZON.UAS7_SEMANA_1_DIA6_PICAZON_R1;
      this.week1Day6Wheal = this.answers.SEMANA1.DIA6.RONCHAS.UAS7_SEMANA_1_DIA6_RONCHAS_R1;
      this.week1Day7Itchign = this.answers.SEMANA1.DIA7.PICAZON.UAS7_SEMANA_1_DIA7_PICAZON_R1;
      this.week1Day7Wheal = this.answers.SEMANA1.DIA7.RONCHAS.UAS7_SEMANA_1_DIA7_RONCHAS_R1;

      this.week2Day1Itchign = this.answers.SEMANA2.DIA1.PICAZON.UAS7_SEMANA_2_DIA1_PICAZON_R1;
      this.week2Day1Wheal = this.answers.SEMANA2.DIA1.RONCHAS.UAS7_SEMANA_2_DIA1_RONCHAS_R1;
      this.week2Day2Itchign = this.answers.SEMANA2.DIA2.PICAZON.UAS7_SEMANA_2_DIA2_PICAZON_R1;
      this.week2Day2Wheal = this.answers.SEMANA2.DIA2.RONCHAS.UAS7_SEMANA_2_DIA2_RONCHAS_R1;
      this.week2Day3Itchign = this.answers.SEMANA2.DIA3.PICAZON.UAS7_SEMANA_2_DIA3_PICAZON_R1;
      this.week2Day3Wheal = this.answers.SEMANA2.DIA3.RONCHAS.UAS7_SEMANA_2_DIA3_RONCHAS_R1;
      this.week2Day4Itchign = this.answers.SEMANA2.DIA4.PICAZON.UAS7_SEMANA_2_DIA4_PICAZON_R1;
      this.week2Day4Wheal = this.answers.SEMANA2.DIA4.RONCHAS.UAS7_SEMANA_2_DIA4_RONCHAS_R1;
      this.week2Day5Itchign = this.answers.SEMANA2.DIA5.PICAZON.UAS7_SEMANA_2_DIA5_PICAZON_R1;
      this.week2Day5Wheal = this.answers.SEMANA2.DIA5.RONCHAS.UAS7_SEMANA_2_DIA5_RONCHAS_R1;
      this.week2Day6Itchign = this.answers.SEMANA2.DIA6.PICAZON.UAS7_SEMANA_2_DIA6_PICAZON_R1;
      this.week2Day6Wheal = this.answers.SEMANA2.DIA6.RONCHAS.UAS7_SEMANA_2_DIA6_RONCHAS_R1;
      this.week2Day7Itchign = this.answers.SEMANA2.DIA7.PICAZON.UAS7_SEMANA_2_DIA7_PICAZON_R1;
      this.week2Day7Wheal = this.answers.SEMANA2.DIA7.RONCHAS.UAS7_SEMANA_2_DIA7_RONCHAS_R1;


      this.week3Day1Itchign = this.answers.SEMANA3.DIA1.PICAZON.UAS7_SEMANA_3_DIA1_PICAZON_R1;
      this.week3Day1Wheal = this.answers.SEMANA3.DIA1.RONCHAS.UAS7_SEMANA_3_DIA1_RONCHAS_R1;
      this.week3Day2Itchign = this.answers.SEMANA3.DIA2.PICAZON.UAS7_SEMANA_3_DIA2_PICAZON_R1;
      this.week3Day2Wheal = this.answers.SEMANA3.DIA2.RONCHAS.UAS7_SEMANA_3_DIA2_RONCHAS_R1;
      this.week3Day3Itchign = this.answers.SEMANA3.DIA3.PICAZON.UAS7_SEMANA_3_DIA3_PICAZON_R1;
      this.week3Day3Wheal = this.answers.SEMANA3.DIA3.RONCHAS.UAS7_SEMANA_3_DIA3_RONCHAS_R1;
      this.week3Day4Itchign = this.answers.SEMANA3.DIA4.PICAZON.UAS7_SEMANA_3_DIA4_PICAZON_R1;
      this.week3Day4Wheal = this.answers.SEMANA3.DIA4.RONCHAS.UAS7_SEMANA_3_DIA4_RONCHAS_R1;
      this.week3Day5Itchign = this.answers.SEMANA3.DIA5.PICAZON.UAS7_SEMANA_3_DIA5_PICAZON_R1;
      this.week3Day5Wheal = this.answers.SEMANA3.DIA5.RONCHAS.UAS7_SEMANA_3_DIA5_RONCHAS_R1;
      this.week3Day6Itchign = this.answers.SEMANA3.DIA6.PICAZON.UAS7_SEMANA_3_DIA6_PICAZON_R1;
      this.week3Day6Wheal = this.answers.SEMANA3.DIA6.RONCHAS.UAS7_SEMANA_3_DIA6_RONCHAS_R1;
      this.week3Day7Itchign = this.answers.SEMANA3.DIA7.PICAZON.UAS7_SEMANA_3_DIA7_PICAZON_R1;
      this.week3Day7Wheal = this.answers.SEMANA3.DIA7.RONCHAS.UAS7_SEMANA_3_DIA7_RONCHAS_R1;

      this.week4Day1Itchign = this.answers.SEMANA4.DIA1.PICAZON.UAS7_SEMANA_4_DIA1_PICAZON_R1;
      this.week4Day1Wheal = this.answers.SEMANA4.DIA1.RONCHAS.UAS7_SEMANA_4_DIA1_RONCHAS_R1;
      this.week4Day2Itchign = this.answers.SEMANA4.DIA2.PICAZON.UAS7_SEMANA_4_DIA2_PICAZON_R1;
      this.week4Day2Wheal = this.answers.SEMANA4.DIA2.RONCHAS.UAS7_SEMANA_4_DIA2_RONCHAS_R1;
      this.week4Day3Itchign = this.answers.SEMANA4.DIA3.PICAZON.UAS7_SEMANA_4_DIA3_PICAZON_R1;
      this.week4Day3Wheal = this.answers.SEMANA4.DIA3.RONCHAS.UAS7_SEMANA_4_DIA3_RONCHAS_R1;
      this.week4Day4Itchign = this.answers.SEMANA4.DIA4.PICAZON.UAS7_SEMANA_4_DIA4_PICAZON_R1;
      this.week4Day4Wheal = this.answers.SEMANA4.DIA4.RONCHAS.UAS7_SEMANA_4_DIA4_RONCHAS_R1;
      this.week4Day5Itchign = this.answers.SEMANA4.DIA5.PICAZON.UAS7_SEMANA_4_DIA5_PICAZON_R1;
      this.week4Day5Wheal = this.answers.SEMANA4.DIA5.RONCHAS.UAS7_SEMANA_4_DIA5_RONCHAS_R1;
      this.week4Day6Itchign = this.answers.SEMANA4.DIA6.PICAZON.UAS7_SEMANA_4_DIA6_PICAZON_R1;
      this.week4Day6Wheal = this.answers.SEMANA4.DIA6.RONCHAS.UAS7_SEMANA_4_DIA6_RONCHAS_R1;
      this.week4Day7Itchign = this.answers.SEMANA4.DIA7.PICAZON.UAS7_SEMANA_4_DIA7_PICAZON_R1;
      this.week4Day7Wheal = this.answers.SEMANA4.DIA7.RONCHAS.UAS7_SEMANA_4_DIA7_RONCHAS_R1;
    },
  },
};
</script>

<style scoped>
.week-result {
  font-size: 23px;
}
</style>
